import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Container from 'react-bootstrap/Container'
import { ProductList } from './components'

const App = () => {
  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }

    document.addEventListener('contextmenu', handleContextmenu)
    
    return () => {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  return (
    <Container>
      <Helmet>
        <title>Loop Product Catalogue</title>
        <meta content="noindex, nofollow" name="robots" />
      </Helmet>

     <ProductList />
      
    </Container>
  )
}

export default App
